import Vue from 'vue'
import Vuex from 'vuex'

import {default as auth, userTokenWatcher} from './modules/auth'
import users from './modules/users/index'
import posts from './modules/posts'
import permissions from './modules/permissions'
import clientApps from './modules/clientApps/index'
import serverApps from './modules/serverApps'
import domains from './modules/domains/index'
import customers from './modules/customers'
import providers from "./modules/providers";
import domain from "./modules/domainsNames";

Vue.use(Vuex)

const store = new Vuex.Store({
    getters: {
        me: state => state.auth.me
    },
    modules: {
        auth,
        users,
        posts,
        permissions,
        clientApps,
        providers,
        serverApps,
        domains,
        customers,
        domain
    }
})

store.watch(...userTokenWatcher)

export default store
